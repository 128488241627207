import React, { useState , useEffect} from "react";
import { Modal} from 'react-bootstrap';
import { asset } from '../../helpers/dom.helper';
import moment from "moment";
import './popup.scss';
import {isMobile} from 'react-device-detect';

export default function PopupBanner() {

    const [visited, setVisible] = useState(false)

    useEffect(()=>{
        let pop_status = localStorage.getItem('pop_status');
        let pop_date = localStorage.getItem('pop_date_start');
        let today = new Date()
  
        if (!pop_date || pop_date.trim() === '') {
          localStorage.removeItem('pop_date_start');
        }

        if(!pop_status){
          setVisible(true);
          localStorage.setItem('pop_status',1);
          localStorage.setItem('pop_date_start',today)
        }
  
        if(pop_status){
          checkPopupStatus()
        }
   
      },[])
  
      const checkPopupStatus = () => {
          let today = new Date()
          let pop_date_start = localStorage.getItem('pop_date_start');
          let now = moment();
          let todayDate = now.date(); 
          let pop_date_start_date = new Date(pop_date_start).getDate()
    
          if(pop_date_start_date && (todayDate > pop_date_start_date)){
              localStorage.setItem('pop_date_start',today)
              setVisible(true)
          }else{
              setVisible(false)
          }
      }

    return (
        <>
            <Modal show={visited}  id="modal"  size="lg" aria-labelledby="contained-modal-title-vcenter"  centered 
              className='modal-popup-banner'>
                <div style={{position:'revert'}} className="text-center">  
                    {isMobile && <a href="tel:+66639752424">
                      <img src={asset('/images/banner/posterAdvisory.webp')} alt="posterAdvisory" className="img-fluid" style={{maxWidth:'100%'}}/>
                    </a>}
                    {!isMobile && 
                      <img src={asset('/images/banner/posterAdvisory.webp')} alt="posterAdvisory" className="img-fluid" style={{maxWidth:'100%'}}/>
                    }
                    <img onClick={() => setVisible(false) }
                        src={asset('/images/banner/Union.png')}
                        alt="close" style={{width:'20px',position:'absolute',right:'3%',top:'4%',cursor:'pointer'}}/> 
                </div>
            </Modal>
       </>
    );
  
}

